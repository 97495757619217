<template>
  <div class="e2_timer_container">
    <div class="e2_timer">
      <E2PancarteCountdown
        class="pancarte_countdown"
        v-if="e2_countdown !== null"
        :n="e2_countdown"
      />

      <span v-if="e2_mot !== null" class="mot_a_dessiner_container">
        <div style="margin-top: 20px;font-family: cabin_sketch_regular;font-size: 30px;">
          {{ $t('sketchy.page_jeu.etat_2.dois_dessiner') }}
        </div>

        <E2MotADessiner :e2_mot="e2_mot" class="mot_a_dessiner"/>
      </span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '~@/resources/libraries/bulma-0.9.0/sass/utilities/_all.sass';

.e2_timer_container {



  @include mobile {
    z-index: 1000;

    background-image: url('~@/resources/images/notebook_background_frosted.png');
    background-repeat: repeat;

    border-bottom: 1px solid rgba(0, 0, 0, 0.25);

    position: fixed;

    width: 100%;
    height: 90px;

    top: 0;
    left: 0;
    right: 0;

    margin-left: auto;
    margin-right: auto;
  }

  @include tablet-only {
    z-index: 1000;

    background-image: url('~@/resources/images/notebook_background_frosted.png');
    background-repeat: repeat;

    border-bottom: 1px solid rgba(0, 0, 0, 0.25);

    position: fixed;

    width: 100%;
    height: 150px;

    top: 0;
    left: 0;
    right: 0;

    margin-left: auto;
    margin-right: auto;
  }

  .e2_timer {
    transform-origin: top left;
    $cur-size: 240px;

    @include desktop {
      //background-color: yellow;
      $width: 195px;
      max-width: $width;
      transform: scale(#{$width / $cur-size}, #{$width / $cur-size})
    }

    @include widescreen {
      //background-color: lime;
      max-width: 100%;
      transform: none;
    }

    @include fullhd {
      //background-color: forestgreen;
      max-width: 100%;
      transform: none;
    }

    .mot_a_dessiner_container {
      .mot_a_dessiner {
        transform-origin: top left;

        @include desktop {
          transform: scale(0.7, 0.7);
        }

        @include widescreen {
          transform: scale(0.62, 0.62);
        }

        @include fullhd {
          transform: scale(0.85, 0.85);
        }
      }

      transform-origin: top left;

      @include mobile {
        //background-color: red;
        transform: scale(0.5, 0.5);
        position: fixed;
        min-width: 50%;
        width: calc(200vw - 300px);
        left: 15px;
      }

      @include tablet-only {
        //background-color: #F0F;
        transform: scale(0.85, 0.85);
        position: fixed;
        min-width: 50%;
        width: calc(117vw - 220px);
        left: 15px;
      }
    }



    .pancarte_countdown {
      transform-origin: top right;

      @include mobile {
        transform: scale(0.5, 0.5);
        position: fixed;
        top: 0;
        right: 15px;
      }

      @include tablet-only {
        transform: scale(0.7, 0.7);
        position: fixed;
        top: 15px;
        right: 15px;
      }
    }
  }
}
</style>

<script>
export default {
  name: 'E2Timer',

  components: {
    E2PancarteCountdown: () => import('./E2PancarteCountdown'),
    E2MotADessiner: () => import('@/views/components/salon/E2MotADessiner'),
  },

  props: ['e2_countdown', 'e2_mot'],
}
</script>
